import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../firestore_service/firestore.service';

@Component({
  selector: 'app-confirm-purchase',
  templateUrl: './confirm-purchase.component.html',
  styleUrls: ['./confirm-purchase.component.scss']
})
export class ConfirmPurchaseComponent implements OnInit {
  
  orderId;

  confirmed = false;

  constructor(private activatedRoute: ActivatedRoute, private storeService: FirestoreService, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(s => {
      console.log(s,s["orderId"])
      this.orderId = s['orderId'];

      if(this.orderId){
        this.storeService.getOrderStream(this.orderId).subscribe((val)=>{
          console.log("This is the data", val.payload.data());
          this.confirmed = val.payload.data()['confirmed'];


        })
      }
      else{
        this.router.navigateByUrl('/home')
      }
      


    });
  }

  goHome(){
    this.router.navigateByUrl("/home");
  }

}
