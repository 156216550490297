// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  productsCollection: "Products",
  ordersCollection: "Orders",
  categoriesCollection: "Categories",
  blogsCollection: "BlogPosts",
  websiteAuctionsCollection: "WebsiteAuctions",
  usersCollection: "Users",
  dynamicContentCollection: "DynamicContent",
  activeAuctionCollectionName: "ActiveAuctions",
  firebaseConfig : {
    apiKey: "AIzaSyAL6Sp6NWnHvS5wxs63M9-cwf5RY1Chx2g",
    authDomain: "asphalios.firebaseapp.com",
    projectId: "asphalios",
    storageBucket: "asphalios.appspot.com",
    messagingSenderId: "773284434551",
    appId: "1:773284434551:web:758f9fd844011336bcec72",
    measurementId: "G-E7J7P20MM0"
  }
};
