import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartProduct } from 'app/store/models/product.model';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore_service/firestore.service';
import { Category } from '../interfaces/category';
import { Product } from '../interfaces/product';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private storeService: FirestoreService, private router: Router) { }

  categories: Category[];

  loading = true;


  ngOnInit(){
    this.getCategories();
  }


  

  async getCategories(){
    this.categories = await this.storeService.getCategories(200) as [];
    this.loading = false;
    
  }

  goToCategory(category: Category){
    this.router.navigateByUrl(`category/${category.name}`)

  }

  


}
