import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../firestore_service/firestore.service';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {

  constructor( private storeService: FirestoreService ) { }

  blogPosts = [];

  ngOnInit(): void {
    this.loadPosts();
  }

  async loadPosts() {
    this.blogPosts = await this.storeService.getBlogPosts(20);
    console.log("This is the product Data", this.blogPosts);
    

  }

}
