import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Product } from 'app/new/interfaces/product';
import { AddItemAction } from 'app/store/actions/product.action';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: Product;

  @Input() inCart;

  @Input() showViewInCartButton;

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
  }

  addToCart(event){
    console.log("Adding to cart");
    this.store.dispatch(new AddItemAction(
      {
        name: this.product.name,
        quantity: 1,
        price: this.product.price,
        id: this.product.id,
        imgSrc: this.product.imgSrc,
        adOns: {}
      }
    ))

    event.stopPropagation();
    
    
  }

  goToCart(){
    this.router.navigateByUrl("/cart");
  }

  goToProduct(){
    console.log("Going to product", this.product.id);
    this.router.navigateByUrl(`product/${this.product.id}`);
  }

}
