import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from '../firestore_service/firestore.service';

@Component({
  selector: 'app-reef-maintenance',
  templateUrl: './reef-maintenance.component.html',
  styleUrls: ['./reef-maintenance.component.scss']
})
export class ReefMaintenanceComponent implements OnInit {

  constructor( private storeService: FirestoreService, private router: Router) { }

  pageContent;

  routeName = "reef-maintenance";

  ngOnInit(): void {
    this.loadPageContent();

  }

  async loadPageContent(){
    var data = await this.storeService.loadRouteDynamicContent(this.routeName);

    console.log("This is the page data", data);

    this.pageContent = data;
  }

}
