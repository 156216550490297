import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';
import { forkJoin, Observable } from 'rxjs';
import { FirestoreService } from '../firestore_service/firestore.service';
import { Product } from '../interfaces/product';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  zoom: number = 14;
  lat: number = 44.445248;
  lng: number = 26.099672;

  user: User = {
    "fullName": "Hello",
    "email": "..."
  };

  styles: any[] = [{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }];
  data: Date = new Date();
  focus;
  focus1;

  currentTime = 0;

  reloadedPage = false;


  tabIds = {
    'ngb-tab-0': "Active Auctions",
    'ngb-tab-1': "Past Purchases"
  };

  activeTab = 'ngb-tab-0';

  modalText = "";

  auctionProducts = [];

  myMaxBid = 0;

  selectedProduct;

  processing = false;

  pastPurchasesProducts$: Observable<any[]>;

  constructor(private modalService: NgbModal, private auth: AngularFireAuth, private router: Router, private storeService: FirestoreService, private ref: ChangeDetectorRef) { }

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('profile-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');

    this.checkIfUserIsLoggedIn();

    this.getAuctionProducts();

    this.startCountdown();

    

    


  }

  async getAuctionProducts(){
    this.auctionProducts = await this.storeService.getItemsOnAuction() as Product[];


  }

  startCountdown() {
    this.currentTime = new Date().getTime();
    const intervalId = setInterval(() => {
      // Calculate the time left until the countdown date
      
      

      //Each second you add a second to the time
      this.currentTime += 1000;

      
    }, 1000);
  }

  getCountdownString(item){
    // console.log("Getting countdown string for", item);
    //Get the ending time of the bid
    var timeOfEndingInSeconds = item.endDate.toDate().getTime();

    if(this.currentTime > timeOfEndingInSeconds){
      
      
      
      return "Auction Over";
    }

    

    var secondsLeft = (timeOfEndingInSeconds - this.currentTime)/1000;

    

    var result = this.convertSecondsToCountdownString(secondsLeft);

    

    return result;

  }

  isCountdownOver(item){

    

    var timeOfEndingInSeconds = item.endDate.toDate().getTime();

    

    if(this.currentTime > timeOfEndingInSeconds){
      
      
      
      return true;
    }
    return false;

  }

  convertSecondsToCountdownString(seconds: number): string {

    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
  
    let countdownString = "";
    if (days > 0) {
      countdownString += `${days} days `;
    }
    if (hours > 0 || countdownString.length > 0) {
      countdownString += `${hours} hours `;
    }
    if (minutes > 0 || countdownString.length > 0) {
      countdownString += `${minutes} minutes `;
    }
    if (remainingSeconds >= 0 || countdownString.length > 0) {
      countdownString += `${remainingSeconds} seconds`;
    }
  

    
    return countdownString;
  }

  async loadUserData(userId: string) {
    var userData = await this.storeService.getUserFromId(userId) as Object;
    
    if (userData != false) {
      console.log("This is the user Data", userData);
      this.user = userData as User;
      this.ref.detectChanges();
      console.log("This is the user",this.user);
      

      //Load the users past purchases
      this.pastPurchasesProducts$ = forkJoin(
        this.user.pastPurchases.map(async id => {
          var response = await this.storeService.getProductFromId(id);

          console.log("This is the response", response);

          if(response == false){
            return null;
          }
          else{
            return response as Product;
          }
        })
      );

      console.log("These are the past purchases before returning", this.pastPurchasesProducts$);


    }
    
    // else {
    //   this.loading = false;

    //   this.primaryImage = this.product.imgSrc;
    //   this.allImages = [this.product.imgSrc, ...this.product.images];
    //   this.price = this.product.price;
    // }

  }

  getYourMaxBidForThisItem(product){
    var myCurrentBid = product?.maxBids?.[this.user.id] ?? 0;
    
    return myCurrentBid.toString();
  }


  showCoralChipsModal(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  async placeBid(alertContent){

    this.processing = true;
    

    if(!this.user.id){
      //There is no user id
      this.processing = false;
      return;
    }


    //Check if your max bid is greater than the current bid
    var currentBidOnItem = this.getYourMaxBidForThisItem(this.selectedProduct);

    if(this.myMaxBid < currentBidOnItem || this.myMaxBid < this.selectedProduct.price){
      this.modalText = `The minimum bid for this item is ${currentBidOnItem > this.selectedProduct.price? currentBidOnItem: this.selectedProduct.price}`
      this.modalService.open(alertContent, { ariaLabelledBy: 'modal-basic-title' });
      this.processing = false;
      return;
    }

  
  
    await this.storeService.setMaxBidForProduct(this.selectedProduct, this.myMaxBid, this.user);

    this.processing = false;

    this.modalService.dismissAll();

    this.modalText = "Bid Placed Successfully";
    this.modalService.open(alertContent);
    
    setTimeout(() => {
      this.getAuctionProducts();  
      
      window.location.reload();
    }, 2000);
    

  }

  checkIfUserIsLoggedIn() {
    this.auth.onAuthStateChanged((val) => {
      
      if (val != null) {

        console.log("Loading user data", val.uid);
        this.loadUserData(val.uid);
        

      }
      else {
        this.router.navigateByUrl("/sign-in")
      }

    })

  }

  tabChanged(val) {
    console.log("Tab changed", val);
    this.activeTab = val.nextId;
  }


  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('profile-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }


  open(content, product) {

    if(this.currentTime > product.endDate.toDate().getTime()){
      return;
    }

    this.selectedProduct = product;
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
	}

  logOut(){
    this.storeService.signOut();
    this.router.navigateByUrl("/home");
  }


}
