// import the interface

import { ProductAction, ProductActionType } from "../actions/product.action";
import { CartProduct } from "../models/product.model";

//create a dummy initial state
const initialState: Array<CartProduct> = [

];
export function productReducer(
  state: Array<CartProduct> = initialState,
  action: ProductAction
) {
  switch (action.type as any) {
    case ProductActionType.ADD_ITEM:
      console.log("Adding to cart, in reducer");
      //Search to see if the product has 
      //find the index of object from array that you want to update
      console.log("Comparing", action.payload.id);
      const objIndex = state.findIndex(obj => obj.id === action.payload.id);

      if (objIndex > -1) {

        // make new object of updated object.   
        const updatedObj = { ...state[objIndex], quantity: state[objIndex].quantity + 1 };

        // make final new array of objects by combining updated object.
        const updatedstate = [
          ...state.slice(0, objIndex),
          updatedObj,
          ...state.slice(objIndex + 1),
        ];

        return updatedstate;
      }

      return [...state, action.payload];
      break;

    case ProductActionType.REMOVE_ITEM:
      const removeIndex = state.findIndex(obj => obj.id === action.payload.id);

      console.log("Removing item", removeIndex);

      if (removeIndex > -1) {
        // make new object of updated object.   
        if (state[removeIndex].quantity > 1) {
          const updatedObj = { ...state[removeIndex], quantity: state[removeIndex].quantity - 1 };

          // make final new array of objects by combining updated object.
          const updatedstate = [
            ...state.slice(0, removeIndex),
            updatedObj,
            ...state.slice(removeIndex + 1),
          ];

          return updatedstate;
        }
        else {
          var duplicateList = [...state];
          duplicateList.splice(removeIndex, 1);
          return duplicateList;
        }

      }

      return state;

    case ProductActionType.DELETE_ITEM:
      const deleteIndex = state.findIndex(obj => obj.id === action.payload.id);

      var duplicateList = [...state];
      duplicateList.splice(deleteIndex, 1);
      return duplicateList;

    default:
      console.log("Defaulting state");
      return state;
  }
}