import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartProduct } from 'app/store/models/product.model';
import { AppState } from 'app/store/models/state.model';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore_service/firestore.service';
import { Category } from '../interfaces/category';
import { Product } from '../interfaces/product';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private storeService: FirestoreService, private router: Router) { }


    loading = true;
    notAProduct = false;

  cartItems$: Observable<Array<CartProduct>>;

  products: Product[] = [];

  cartProducts: Array<CartProduct>;

  productsIdsInCart: Array<String>;

  categoryId: string;

  ngOnInit(): void {
    
    const routeParams = this.route.snapshot.paramMap;
    this.categoryId = String(routeParams.get('categoryId'));

    // Find the product that correspond with the id provided in route.
    this.loadProducts(this.categoryId);

    
    this.cartItems$ = this.store.select((store) => store.cart);
    this.cartItems$.subscribe((val) => {
        //Each time a new item is added to the cart then you updated a list of product ids that
        this.cartProducts = val;
        this.productsIdsInCart = val.map((val)=> val.id);
        

    })

  }

  async loadProducts(categoryId: string) {
    var productData = await this.storeService.getProductsFromCategory(categoryId, 100);
    console.log("This is the product Data", productData);
    if (productData == false) {
      this.notAProduct = true;
    }
    else {
      this.loading = false;
      this.products = productData as [];
      
    }

  }

  navigate(url){
    this.router.navigateByUrl(url);
  }
}
