import { Component, OnInit } from '@angular/core';
import { CartProduct } from 'app/store/models/product.model';
import { Observable } from 'rxjs-compat';
import { Product } from '../interfaces/product';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../firestore_service/firestore.service';
import { AppState } from 'app/store/models/state.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(private route: ActivatedRoute, private storeService: FirestoreService, private store: Store<AppState>, private router: Router) { }
  loading = true;
  notAProduct = false;

  cartItems$: Observable<Array<CartProduct>>;

  products: Product[] = [];

  cartProducts: Array<CartProduct>;

  productsIdsInCart: Array<String>;

  queryString: string;

  ngOnInit(): void {


    console.log("Hi there");

    this.route.queryParams
      
      .subscribe(params => {
        console.log(params); // { order: "popular" }
        this.products = [];
        this.queryString = params.query;
        console.log("Query is", this.queryString);
        this.loadProducts(this.queryString);


      }
      );

    this.cartItems$ = this.store.select((store) => store.cart);
    this.cartItems$.subscribe((val) => {
      //Each time a new item is added to the cart then you updated a list of product ids that
      this.cartProducts = val;
      this.productsIdsInCart = val.map((val) => val.id);


    })

  }

  async loadProducts(queryString: string) {
    var productData = await this.storeService.searchForProducts(queryString, 30);
    console.log("This is the product Data", productData);
    if (productData == false) {
      console.log("Product data is false");
      this.loading = true;
      this.notAProduct = true;
    }
    else {
      this.loading = false;
      this.products = productData as [];

    }

  }

  navigate(url){
    this.router.navigateByUrl(url);
  }

}
