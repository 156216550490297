import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {


  showVideo = true;
  

  constructor() { }

  ngOnInit(): void {
  }


  showIntroVideo() {
    console.log("This is working");
    this.showVideo = !this.showVideo;
  }

  toggleVideo() {
    console.log("Toggle");
    this.showVideo = !this.showVideo;
  }


}
