import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { FirestoreService } from '../firestore_service/firestore.service';

@Component({
  selector: 'app-tank-set-up',
  templateUrl: './tank-set-up.component.html',
  styleUrls: ['./tank-set-up.component.scss']
})
export class TankSetUpComponent implements OnInit {

  constructor( private storeService: FirestoreService, private router: Router) { }

  pageContent;

  routeName = "tank-set-up";

  ngOnInit(): void {
    this.loadPageContent();

  }

  async loadPageContent(){
    var data = await this.storeService.loadRouteDynamicContent(this.routeName);

    console.log("This is the page data", data);

    this.pageContent = data;
  }
}
