import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CartComponent } from './cart/cart.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ProductComponent } from './product/product.component';
import { ProductCardComponent } from './custom-components/product-card/product-card.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { ConfirmPurchaseComponent } from './confirm-purchase/confirm-purchase.component';
import { RouterModule } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { AboutComponent } from './about/about.component';
import { WaterDeliveryComponent } from './water-delivery/water-delivery.component';
import { ReefMaintenanceComponent } from './reef-maintenance/reef-maintenance.component';
import { TankSetUpComponent } from './tank-set-up/tank-set-up.component';
import { SigninComponent } from './signin/signin.component';
import { ProfileComponent } from './profile/profile.component';
import { ServicesComponent } from './services/services.component';
import { DiscoverComponent } from './discover/discover.component';
import { SearchComponent } from './search/search.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgbModule,
    ],
    declarations: [
        
    HomeComponent,
        
    CatalogComponent,
             CartComponent,
             TermsAndConditionsComponent,
             CheckoutComponent,
             ProductComponent,
             ProductCardComponent,
             CategoryComponent,
             ContactComponent,
             CheckoutComponent,
             ConfirmPurchaseComponent,
             ProductsComponent,
             AboutComponent,
             WaterDeliveryComponent,
             ReefMaintenanceComponent,
             TankSetUpComponent,
             SigninComponent,
             ProfileComponent,
             ServicesComponent,
             DiscoverComponent,
             SearchComponent


]
})
export class NewModule { }
