import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from '../firestore_service/firestore.service';

@Component({
  selector: 'app-water-delivery',
  templateUrl: './water-delivery.component.html',
  styleUrls: ['./water-delivery.component.scss']
})
export class WaterDeliveryComponent implements OnInit {

  constructor( private storeService: FirestoreService, private router: Router) { }

  pageContent;

  routeName = "water-delivery";

  ngOnInit(): void {
    this.loadPageContent();

  }

  async loadPageContent(){
    var data = await this.storeService.loadRouteDynamicContent(this.routeName);

    console.log("This is the page data", data);

    this.pageContent = data;
  }


}
