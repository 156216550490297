import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartProduct } from 'app/store/models/product.model';
import { AppState } from 'app/store/models/state.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user';
import { FirestoreService } from '../firestore_service/firestore.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  cartItems$: Observable<Array<CartProduct>>;

  cartProducts: Array<CartProduct>;

  checkOutEndpoint = "https://us-central1-asphalios.cloudfunctions.net/makePurchase";

  sending = false;

  submitted = false;

  isMobile: boolean = false;

  deliver = false;

  user: User;


  date;
  
  name = new FormControl("",[Validators.required]);
  phoneNumber = new FormControl("",[Validators.required]);
  postalCode = new FormControl("",[Validators.required]);
  address = new FormControl("",[Validators.required]);


  


  constructor(private auth: AngularFireAuth, private store: Store<AppState>, private router: Router, private http: HttpClient, private deviceService: DeviceDetectorService, private storeService: FirestoreService) { 
    
  }

  ngOnInit(): void {


    const now = new Date();

    let day = now.getDate();
    let month = now.getMonth() + 1;
    let year = now.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    this.date = `${day}-${month}-${year}`;

    //Redirect the user to the sign up page if 
    this.auth.onAuthStateChanged((val) => {
      console.log("Log in", val);
      if (!val) {
        this.router.navigate(["sign-in"], {
          queryParams: {
            to: "checkout"
          }

        })



      }
      else {
        this.loadUserData(val.uid);
      }


    })





    this.cartItems$ = this.store.select((store) => store.cart);
    this.cartItems$.subscribe((val) => {
      //Each time a new item is added to the cart then you updated a list of product ids that
      this.cartProducts = val;
      console.log(this.cartProducts);

      if (this.cartProducts.length < 1) {
        //Navigate home 
        this.router.navigateByUrl("/home");
      }


    })
  }

  radioSelected(val){
    console.log("Working");
    var target = val.target;
      this.deliver = !this.deliver;
  }



  


  async loadUserData(userId: string) {
    var userData = await this.storeService.getUserFromId(userId) as Object;

    if (userData != false) {
      console.log("This is the user Data", userData);
      this.user = userData as User;





    }
    // else {
    //   this.loading = false;

    //   this.primaryImage = this.product.imgSrc;
    //   this.allImages = [this.product.imgSrc, ...this.product.images];
    //   this.price = this.product.price;
    // }

  }

  calculateTotal() {
    var total = 0;
    this.cartProducts.forEach(element => {
      total += element.price * element.quantity;
    });

    if(this.deliver){
      if(total < 100){
        total += 20;
      }
      
    }

    return total;

  }

  checkout() {
    this.sending = true;
    this.submitted = true;

  

    var processedProductList = this.cartProducts.map((val) => {
      return {
        id: val.id,
        product_name: val.name,
        quantity: val.quantity,
        adOns: val.adOns
      }
    });

    console.log("Processed product list", processedProductList);

    if(this.deliver){
      if(this.address.valid && this.name.valid && this.postalCode.valid && this.phoneNumber.valid){

      }
      else{
        this.sending = false;
        return;
      }
    }

    this.http.post<any>(this.checkOutEndpoint, {
      "product_list": processedProductList,
      "email": this.user.email,
      "deliver": this.deliver,
      "address": `(${this.name.value}: ${this.phoneNumber.value}) ${this.address.value} ${this.postalCode.value}`,
      "pickupDate": this.date
    }).subscribe({
      next: data => {
        this.sending = false;

        console.log("This is the data", data);



        //Navigate to the fomo payment page
        window.open(data);


      },
      error: error => {
        this.sending = false;


        console.log('There was an error!', error);
      }
    })
  }
}
