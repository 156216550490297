import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AddItemAction, DeleteItemAction, RemoveItemAction } from 'app/store/actions/product.action';
import { CartProduct } from 'app/store/models/product.model';
import { AppState } from 'app/store/models/state.model';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore_service/firestore.service';
import { Product } from '../interfaces/product';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {


  cartItems$: Observable<Array<CartProduct>>;

  products: Product[] = [];

  cartProducts: Array<CartProduct>;

  productsIdsInCart: Array<String>;

  termsAndConditions: boolean;

  isMobile :boolean = false;

  date;

  constructor(private store: Store<AppState>, private router: Router, private storeService: FirestoreService, private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    // this.storeService.populateServer();
    this.isMobile = this.deviceService.isMobile();


    this.getHomePageProducts();
    this.cartItems$ = this.store.select((store) => store.cart);
    this.cartItems$.subscribe((val) => {
      //Each time a new item is added to the cart then you updated a list of product ids that
      this.cartProducts = val;
      this.productsIdsInCart = val.map((val) => val.id);


    })



    const now = new Date();

    let day = now.getDate();
    let month = now.getMonth() + 1;
    let year = now.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    this.date = `${day}-${month}-${year}`;

  }

  goToProduct(productId) {
    console.log("Going to product", productId);
    this.router.navigateByUrl(`product/${productId}`);
  }


  addToCart(product: Product) {
    console.log("Adding to cart");
    this.store.dispatch(new AddItemAction(
      {
        name: product.name,
        quantity: 1,
        price: product.price,
        id: product.id,
        imgSrc: product.imgSrc,
        adOns: {}
      }
    ))

  }

  checkOut(){
    if(!this.termsAndConditions){
      alert("You need to accept the terms and conditions");
      return;
    }
    this.navigate('/checkout');
  }

  calculateTotal() {
    var total = 0;
    this.cartProducts.forEach(element => {
      total += element.price * element.quantity;
    });

    return total;

  }

  async getHomePageProducts() {
    this.products = await this.storeService.getProducts(3) as Product[];

    console.log(this.products);

  }

  increment(product: CartProduct) {
    this.store.dispatch(new AddItemAction(product))
  }

  decrement(product: CartProduct) {
    this.store.dispatch(new RemoveItemAction(product))
  }

  delete(product: CartProduct) {
    this.store.dispatch(new DeleteItemAction(product))
  }

  navigate(route: string) {
    this.router.navigateByUrl(route);
  }

}
