import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './examples/landing/landing.component';
import { LoginComponent } from './examples/login/login.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { HomeComponent } from './new/home/home.component';
import { CatalogComponent } from './new/catalog/catalog.component';
import { CartComponent } from './new/cart/cart.component';
import { TermsAndConditionsComponent } from './new/terms-and-conditions/terms-and-conditions.component';
import { ProductComponent } from './new/product/product.component';
import { CategoryComponent } from './new/category/category.component';
import { ContactComponent } from './new/contact/contact.component';
import { CheckoutComponent } from './new/checkout/checkout.component';
import { ConfirmPurchaseComponent } from './new/confirm-purchase/confirm-purchase.component';
import { AboutComponent } from './new/about/about.component';
import { WaterDeliveryComponent } from './new/water-delivery/water-delivery.component';
import { ReefMaintenanceComponent } from './new/reef-maintenance/reef-maintenance.component';
import { TankSetUpComponent } from './new/tank-set-up/tank-set-up.component';
import { SigninComponent } from './new/signin/signin.component';
import { ProfileComponent } from './new/profile/profile.component';
import { ServicesComponent } from './new/services/services.component';
import { DiscoverComponent } from './new/discover/discover.component';
import { SearchComponent } from './new/search/search.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'index',                component: ComponentsComponent },
    { path: 'home',                component: HomeComponent },
    { path: 'cart',                component: CartComponent },
    { path: 'contact',                component: ContactComponent },
    { path: 'about',                component: AboutComponent },
    { path: 'checkout',                component: CheckoutComponent },
    // { path: 'water-delivery',                component: WaterDeliveryComponent },
    // { path: 'reef-maintenance',                component: ReefMaintenanceComponent },
    // { path: 'tank-set-up',                component: TankSetUpComponent },
    { path: 'catalog',                component: CatalogComponent },
    { path: 'discover',                component: DiscoverComponent },
    { path: 'services',                component: ServicesComponent },
    { path: 'product/:productId',                component: ProductComponent },
    { path: 'category/:categoryId',                component: CategoryComponent },
    { path: 'terms-and-conditions',  component: TermsAndConditionsComponent },
    { path: 'confirm-purchase',  component: ConfirmPurchaseComponent },
    { path: 'sign-in',  component: SigninComponent },
    { path: 'search',  component: SearchComponent },
    
    { path: 'profile',     component: ProfileComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
