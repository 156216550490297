import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AddItemAction, RemoveItemAction } from 'app/store/actions/product.action';
import { CartProduct } from 'app/store/models/product.model';
import { AppState } from 'app/store/models/state.model';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore_service/firestore.service';
import { Product } from '../interfaces/product';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {


  product: Product;

  //The price of the product is calculated seperately so that you can deal with varations
  price: number;

  loading = true;

  notAProduct = false;

  cartItems$: Observable<Array<CartProduct>>;

  products: Product[] = [];

  cartProducts: Array<CartProduct>;

  productsIdsInCart: Array<String>;

  noToAddToCart = 1;

  primaryImage;

  allImages = [];

  selectedOptions = [[]];

  selectedVariations = {};


  constructor(private route: ActivatedRoute, private storeService: FirestoreService, private router: Router, private store: Store<AppState>) { }

  ngOnInit(): void {
    // First get the product id from the current route.
    this.route.params.subscribe(params => {
      const productIdFromRoute = params['productId'];

    // Find the product that correspond with the id provided in route.
    this.loadProduct(productIdFromRoute);

    this.getHomePageProducts();
    this.cartItems$ = this.store.select((store) => store.cart);
    this.cartItems$.subscribe((val) => {
        //Each time a new item is added to the cart then you updated a list of product ids that
        this.cartProducts = val;
        this.productsIdsInCart = val.map((val)=> val.id);
        

    })

    })
    // const routeParams = this.route.snapshot.paramMap;
    

  }

  

  async getHomePageProducts(){
    this.products = await this.storeService.getProducts(3) as Product[];

    console.log(this.products);

  }

  addToCart(){
    console.log("Adding to cart");
    this.store.dispatch(new AddItemAction(
      {
        name: this.product.name,
        quantity: this.noToAddToCart,
        price: this.price,
        id: this.product.id,
        imgSrc: this.product.imgSrc,
        adOns: this.selectedVariations
      }
    ))
    
  }


  async loadProduct(productId: string) {
    var productData = await this.storeService.getProductFromId(productId);
    console.log("This is the product Data", productData);
    if (productData == false) {
      this.notAProduct = true;
    }
    else {
      this.loading = false;
      this.product = productData as Product;
      console.log(this.product);
      this.primaryImage = this.product.imgSrc;
      console.log(this.primaryImage);
      this.allImages = [this.product.imgSrc, ...this.product.images ?? []];
      console.log(this.allImages);
      this.price = this.product.price;
      
    }

    console.log("This is the price", this.price);

  }


  increment(product: CartProduct) {
    // this.store.dispatch(new AddItemAction(product));
    this.noToAddToCart++;
  }

  decrement(product: CartProduct) {
    // this.store.dispatch(new RemoveItemAction(product));
    this.noToAddToCart--;
  }


  changePrimaryImage(imgSrc){
    this.primaryImage = imgSrc;
  }

  optionChanged(adOn: string, variation: any) {
    console.log('Option changed:', variation);
    if(variation.name == "none"){
      delete this.selectedVariations[adOn];
    }
    else{
      this.selectedVariations[adOn] = variation;
    }

    //Then calculate the new price
    this.price = this.product.price;

    console.log(this.selectedVariations);

    //Then add the price for each of the ad ons
    for (const key in this.selectedVariations) {
      console.log(key, this.selectedVariations[key]);
      this.price += Number(this.selectedVariations[key].price);
    }
  }

  navigate(url){
    this.router.navigateByUrl(url);
  }

  navigateToCategory(category){
    console.log(category);
    this.router.navigateByUrl(`category/${category}`);
  }




}
