import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  sendMessageEndpoint = "https://us-central1-asphalios.cloudfunctions.net/messageFromWebsite";

  submitted = false;

  sending = false;

  activeId = '';


  faqs = [
    {
      "id": "static-1",
      "question":
          "What products do you deal in?",
      "answer":
          "We deal in all products you will need to maintain a household aquarium as well as marine life ranging from corals to beautiful and exotic fish"
    },
    {
      "id": "static-2",
      "question":
          "Do you have an active community?",
      "answer":
          "Yes!. Most of our customers are active on our telegram channel. Join us on telegram where we host live auctions"
    }
  ];

  name = new FormControl("",[Validators.required]);
  phoneNumber = new FormControl("",[Validators.required]);
  message = new FormControl("",[Validators.required]);
  email = new FormControl("",[Validators.required,Validators.email]);

  constructor(private http: HttpClient,private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  sendMessage(successcontent,errorcontent){
    this.submitted = true;
    if(this.email.valid && this.phoneNumber.valid && this.name.valid){


      this.sending = true;
      this.http.post<any>(this.sendMessageEndpoint,{
        "name": this.name.value,
        "message": this.message,
        "email": this.email.value
      }).subscribe({
        next: data => {
          this.sending = false;
          this.open(successcontent);
            
        },
        error: error => {
          this.sending = false;
          this.open(errorcontent);
            
            console.error('There was an error!', error);
        }
    })
    }
    

  }

  open(content) {
    this.modalService.open(content);
  }

  close(){
    this.modalService.dismissAll();
  }

}


