import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Observable } from 'rxjs';
import { CartProduct } from 'app/store/models/product.model';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/models/state.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FirestoreService } from 'app/new/firestore_service/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;

    cartItems$: Observable<Array<CartProduct>>;
    productLength: number;

    isMobile: boolean = false;

    searchMode: boolean = false;

    searchInput = "";

    loggedIn: boolean = false;

    constructor(private router: Router, private auth: AngularFireAuth, private firebaseService : FirestoreService, private store: Store<AppState>, public location: Location, private element: ElementRef,private deviceService: DeviceDetectorService) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.isMobile = this.deviceService.isMobile();

        this.checkIfUserIsLoggedIn();

        // this.storeService.populateServer();
        this.cartItems$ = this.store.select((store) => store.cart);
        this.cartItems$.subscribe((val) => {
            //Each time a new item is added to the cart then you updated a list of product ids that
            this.productLength = val.length;

        })
    }

    searchForProduct(){
        console.log("This is working", this.searchInput);
        if(this.searchInput){
            this.router.navigate(
                ['/search'],
                { queryParams: { query: this.searchInput } }
              );
        }
    }

    toggleSearchMode(){
        this.searchMode = !this.searchMode;
    }

    checkIfUserIsLoggedIn(){
        this.auth.onAuthStateChanged((val)=> {
            console.log("Log in",val);
            if(val){
                this.loggedIn = true;
            }
            else{
                this.loggedIn = false;
            }

        })

    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }
}
