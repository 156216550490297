import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../firestore_service/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  registering = false;

  processing = false;

  errorMessage = "";

  submitted = false;

  password = new FormControl("", [Validators.required]);
  fullName = new FormControl("", [Validators.required]);
  telegramUsername = new FormControl("", [Validators.required]);
  email = new FormControl("", [Validators.required, Validators.email]);

  destination = "/profile";

  constructor(private auth: AngularFireAuth, private firebaseService: FirestoreService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        var tempDest = params.get('to');
        if (tempDest) {
          this.destination = tempDest;
          console.log("Destination", this.destination);
        }

      }


      );


      this.auth.onAuthStateChanged((val)=> {
        console.log("Log in",val);
        if(val){
          this.router.navigateByUrl(`/${this.destination}`);
        }
        

    })


  }

  userRegistering() {
    this.registering = !this.registering;
  }

  signUp() {
    this.submitted = true;

    if (this.password.valid && this.fullName.valid && this.email.valid) {
      this.processing = true;
      this.firebaseService.signUp(this.email.value, this.password.value, this.fullName.value, this.telegramUsername.value).then((val) => {
        this.processing = false;
        if (val == true) {

          // this.router.navigateByUrl(`/${this.destination}`);

        }
        else {
          this.errorMessage = val;
        }
      })
    }

  }

  async forgotPassword(){
    if(this.email.valid){
      var sent = await this.firebaseService.sendPasswordResetEmail(this.email.value);
      if(sent){
        alert("Password reset email sent to " + this.email.value);
      }


    }
    else{
      alert("A valid email address is required");
    }
  }


  signIn() {
    this.submitted = true;
    if (this.password.valid && this.email.valid) {
      this.processing = true;
      this.firebaseService.signIn(this.email.value, this.password.value).then((val) => {
        this.processing = false;
        if (val == true) {
          console.log("Navigating to", this.destination);
          // this.router.navigateByUrl(`/${this.destination}`);

        }
        else {
          this.errorMessage = val;
        }
      })
    }

  }

  performAction() {
    if (this.registering) {
      this.signUp();
    }
    else {
      this.signIn();
    }
  }

}
