import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordionConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AddItemAction } from 'app/store/actions/product.action';
import { CartProduct } from 'app/store/models/product.model';
import { AppState } from 'app/store/models/state.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as Rellax from 'rellax';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore_service/firestore.service';
import { Product } from '../interfaces/product';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  data: Date = new Date();

  page = 4;
  page1 = 5;
  page2 = 3;
  focus;
  focus1;
  focus2;

  date: { year: number, month: number };
  model: NgbDateStruct;

  public isCollapsed = true;
  public isCollapsed1 = true;
  public isCollapsed2 = true;

  state_icon_primary = true;

  products: Product[] = [];

  isMobile: boolean = false;

  cartItems$: Observable<Array<CartProduct>>;

  productsIdsInCart: Array<String>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private renderer: Renderer2, config: NgbAccordionConfig, private storeService: FirestoreService,private deviceService: DeviceDetectorService) {
    config.closeOthers = true;
    config.type = 'info';
  }


  epicFunction() {
    console.log('hello `Home` component');
    
    this.isMobile = this.deviceService.isMobile();
    
    
  }

  navigate(url){
    this.router.navigateByUrl(url);
  }

  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  async getHomePageProducts(){
    this.products = await this.storeService.getProducts(6) as Product[];

    console.log(this.products);

  }


  addToCart(product: Product){
    console.log("Adding to cart");
    this.store.dispatch(new AddItemAction(
      {
        name: product.name,
        quantity: 1,
        price: product.price,
        id: product.id,
        imgSrc: product.imgSrc,
        adOns: {}
      }
    ))
    
  }

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('index-page');

    this.epicFunction();

    this.getHomePageProducts();

    // this.storeService.populateServer();
    this.cartItems$ = this.store.select((store)=> store.cart);
    this.cartItems$.subscribe((val)=>{
      //Each time a new item is added to the cart then you updated a list of product ids that
      this.productsIdsInCart = val.map((val)=> val.id);
    })
  }
  ngOnDestroy() {
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('index-page');
  }

}
