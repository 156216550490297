import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'environments/environment';
import { first } from 'rxjs-compat/operator/first';
import { User } from '../interfaces/user';
import { Product } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private store: AngularFirestore, private auth: AngularFireAuth) { }

  processDataInProducts(data){
  
    return data;
  }

  async signIn(email: string, password: string){
    try{
      var userDetails = await this.auth.signInWithEmailAndPassword(email,password);
      return true;
      
    }
    catch(er){
      console.log(er);
      return er.message;
    }

  }

  async signOut(){
    this.auth.signOut();
  }

  async sendPasswordResetEmail(email: string){
    try{
      await this.auth.sendPasswordResetEmail(email);
      return true;
    }
    catch(er){
      console.log(er);
      return false;
    }
    
  }


  async signUp(email: string, password: string, fullName: string, telegramId: string){
    try{
      var userDetails = await this.auth.createUserWithEmailAndPassword(email,password);
      var userId = userDetails.user.uid;

      //Save the user name to the users collection
      this.store.collection(environment.usersCollection).doc(userId).set({
        'fullName': fullName,
        'email': email,
        'telegramUsername': telegramId
      });

      return true;
      
    }
    catch(er){
      console.log(er);
      return er.message;
    }

  }



  async getProducts(limit: number) {
  
    var data = await this.store.collection(environment.productsCollection,ref => ref.limit(limit)).get().toPromise();
    return data.docs.map((val)=> {return {...(this.processDataInProducts(val.data()) as Object), 'id': val.id}});
  }


  async getBlogPosts(page: number, lastPostId?) {
  
    let noOfPostsPerPage = 20;


    var data;

    if(lastPostId){

      var documentDetails = await this.store.collection(environment.blogsCollection).doc(lastPostId).get().toPromise();

      data = await this.store.collection(environment.blogsCollection,ref => ref.startAfter(documentDetails).limit(noOfPostsPerPage)).get().toPromise();
    }
    else{
      data = await this.store.collection(environment.blogsCollection).get().toPromise();
    }


    

    
    return data.docs.map((val)=> {return {...(this.processDataInProducts(val.data()) as Object), 'id': val.id}});
  }


  async getItemsOnAuction() {
  
    var data = await this.store.collection(environment.websiteAuctionsCollection, ref=> ref.where('endDate', ">", new Date())).get().toPromise();
    return data.docs.map((val)=> {return {...(this.processDataInProducts(val.data()) as Object), 'id': val.id}});
  }


  async loadRouteDynamicContent(route: string){
    var data = await this.store.collection(environment.dynamicContentCollection).doc(route).get().toPromise();
    var routeData = data.data() as Object;

    if(routeData){
      return routeData['htmlContent'];
    }
    else{
      return "<p>Oops.... Seems like there's no data at this route</p>";
    }

    

  }

  async getProductFromId(id: string){
    var data = await this.store.collection(environment.productsCollection).doc(id).get().toPromise();
    if(data.exists){
      return {...(this.processDataInProducts(data.data()) as Object), 'id': data.id};
    }
    else{
      return false;
    }
  }


  async getUserFromId(id: string){
    var data = await this.store.collection(environment.usersCollection).doc(id).get().toPromise();
    if(data.exists){
      return {...(this.processDataInProducts(data.data()) as Object), 'id': data.id};
    }
    else{
      return false;
    }
  }

  async setMaxBidForProduct(product: Product, bid: number, user: User){
    
    var currentBids = product?.maxBids ?? {};

    currentBids[user.id] = bid;

    console.log("These are the current bids", currentBids);

    await this.store.collection(environment.websiteAuctionsCollection).doc(product.id).update({
      maxBids: currentBids
    });
    
  }

  async getProductsFromCategory(category: string, limit: number){
    var data = await this.store.collection(environment.productsCollection,ref => ref.where('category', "==", category).limit(limit)).get().toPromise();
    if(data.docs.length > 0){
      return data.docs.map((val)=> {return {...(this.processDataInProducts(val.data()) as Object), 'id': val.id}});
    }
    else{
      return false;
    }
  }

  async searchForProducts(query:string, limit: number){
    var queryParams = query.split(" ");
    var processed = queryParams.map((val)=> val.toLowerCase());
    var data = await this.store.collection(environment.productsCollection,ref => ref.where('tags', "array-contains-any", processed).limit(limit)).get().toPromise();
    if(data.docs.length > 0){
      return data.docs.map((val)=> {return {...(this.processDataInProducts(val.data()) as Object), 'id': val.id}});
    }
    else{
      return false;
    }

  }

  async getProductsOnAuction(){
    var data = await this.store.collection(environment.activeAuctionCollectionName,).get().toPromise();
    if(data.docs.length > 0){
      return data.docs.map((val)=> {return {...(this.processDataInProducts(val.data()) as Object), 'id': val.id}});
    }
    else{
      return false;
    }
  }

  async getCategories(limit){
    console.log("This is working");
    var data = await this.store.collection(environment.categoriesCollection,ref => ref.limit(limit)).get().toPromise();
    return data.docs.map((val)=> {return {...(val.data() as Object), 'id': val.id}});

  }

  getOrderStream(orderId){
    return this.store.collection(environment.ordersCollection).doc(orderId).snapshotChanges();
  }

  async populateServer(){
    let number = 10;
    for (let ind = 0; ind < number; ind++) {
      this.store.collection(environment.productsCollection).add({
        "name": "Jason Fox High Voltage Platygyra",
        "price": 84,
        "quantity": 20,
        "category": "Fish",
        "description": "This is a test product that should be deleted before release",
        "imgSrc": "https://i.ibb.co/nm6gh3c/rachel-hisko-r-EM3c-K8-F1pk-unsplash-1.jpg"
      });
      
    }
  }
}
