import { Action } from '@ngrx/store';
import { CartProduct } from '../models/product.model';

export enum ProductActionType {
  ADD_ITEM = '[CART] Add Product',
  REMOVE_ITEM = '[CART] Remove Product',
  DELETE_ITEM = '[CART] Delete Product'
}
export class AddItemAction implements Action {
  readonly type = ProductActionType.ADD_ITEM;
  //add an optional payload
  constructor(public payload: CartProduct) {}
}

export class RemoveItemAction implements Action {
  readonly type = ProductActionType.REMOVE_ITEM;
  //add an optional payload
  constructor(public payload: CartProduct) {}
}

export class DeleteItemAction implements Action {
  readonly type = ProductActionType.DELETE_ITEM;
  //add an optional payload
  constructor(public payload: CartProduct) {}
}

export type ProductAction = AddItemAction;